<template>
  <div class="grey-background">
    <notification-popup  
      :dialog="notifSuccess"
      :contentMsg="$t('DescribeSubmitSuccessKyc')"
      :headerMsg="$t('SuccessSubmit')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('BacktoProfile')"
      colorIcon = "success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      disableBtnNo="true"
      :onHandlerYes="successDone"/>


    <notification-popup 
      :dialog="notifFailed"
      :contentMsg="$t('failed')"
      :headerMsg="$t('FailedSubmit')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon = "danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      disableBtnNo="true"
      :onHandlerYes="failed"/>

    <notification-popup 
      :dialog="preSubmit"
      :contentMsg="$t('DescribeSubmitStudentMember')"
      :headerMsg="$t('SubmitPremiumMember')"
      colorheaderMsg="#4BB14E"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      :onHandlerNo="cancelSubmit"
      :onHandlerYes="Confirmation"/>

    <v-container v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }">
      <v-row>
        <v-col cols="12" align="center">
          <h4> {{$t('Selfiewithyourcamera')}}</h4> 
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
          <div class="camera">
            <video v-show="!isPhotoTaken" ref="camera" autoplay class="feed"></video>
          </div>
        </v-col>
      </v-row>

      <v-container v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="camera-shoot" style="width:100%" align="center">
        <v-row justify="center">
          <v-col cols="auto mt-n5">
            <v-img 
              class=""
              max-width="50px"
              max-height="50px"  
              :src="require('../../assets/kyc/buttonCamera.svg')"
              @click="takePhoto()">
            </v-img>
          </v-col>
          <v-col cols="auto mt-n5" style="right: 5%;position: absolute;">
            <v-img
              class="mt-2"
              max-width="30px"
              max-height="30px"  
              :src="require('../../assets/kyc/camera-back.svg')"
              @click="changeCamera()">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
      <!-- <div v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }">
        <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
        <video v-show="!isPhotoTaken" ref="camera" class="camera-stream" :width="250" :height="337.5" autoplay>
        </video>
      </div> -->
    <div v-show="isPhotoTaken" align="center" class="white-background">
      <br>  
      <div v-show="isPhotoTaken">
        <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="250" :height="337.5"></canvas>
      </div>

      <div v-if="isPhotoTaken && isCameraOpen">
        
          <v-btn
          @click="takePhoto"
              color="primary"
              small
              dark
              text
            >
            {{$t('RepeatPhoto')}}
          </v-btn>
      </div>

      <div class="school-information" v-if="isPhotoTaken && isCameraOpen" >
          <v-btn
          class="button-school-information"
          @click="submit()"
              color="primary"
              normal
              dark
              rounded
            >
            {{$t('Submit')}}
          </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'CameraOnSelfie',
    components: { 
    NotificationPopup,
    // WebCam,
    },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,
        preSubmit:false,
        email:"",
        schoolId:"",
        isCameraOpen: true,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photo_selfie : this.$store.state.cdn.photo_selfie,
        dataResponse:"",
      }
    },
    mounted(){
      this.isCameraOpen = true;
      this.frontCamera();
    },
    methods:{
      backCamera(){
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video:{facingMode:{exact:"environment"}}
        });
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
      },
      frontCamera(){
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video : {facingMode:"user"}
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 100;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },
      Confirmation(){
        var canvas = document.getElementById("photoTaken");
        var img    = canvas.toDataURL("image/jpeg");
        var block = img.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var blob = this.b64toBlob(realData, contentType);

        let payload = {
            file: blob,
            filename: this.$store.state.auth.userData.user_name + "_selfie.jpg",
        }

        this.$store
        .dispatch("cdn/uploadPhotoSelfie", payload)
        .then((response) => {
          if (response.status == true) {
            this.dataResponse =  response.photo
          }
        }).then(() =>{
          if (this.dataResponse == ""){
            this.notifFailed = true
          }else{
          let payloadKyc = {
            kycp_photo_passenger: this.dataResponse,
          }

          this.$store
            .dispatch("passenger/selfieKyc", payloadKyc)
            .then((resp) => {
              if (resp.status == true) {
                this.notifSuccess = true
              }
            })
            .catch((err) => {
              this.notifFailed = true
              console.log(err);
            });
          }
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });
      },
      createCameraElement() {
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });
        
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },
      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        const context = this.$refs.canvas.getContext('2d');
        context.canvas.width = window.innerWidth;
        context.canvas.height = 281.25;
        context.drawImage(this.$refs.camera, 0, 0, window.innerWidth, 281.25);
      },
      cancelSubmit(){
        this.preSubmit = false
      },
      successDone() {
        this.notifSuccess = false
        this.$router.push({
          name: 'About',
        })
      },
      failed() {
        this.notifFailed = false
      },
      submit() {
        this.preSubmit = true;
      }
    },
  }
</script>

<style lang="scss" scoped>
.camera {
  width: 100%;
  
  .feed {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
 .camera-stream {
    width: 100%;
    max-height: 70%;
    border: 5px solid #FFFFFF;
  }

.group-1858 {
  margin-right: 75px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  border: 4px solid #FFFFFF;
}
.grey-background {
  position: flex;
  display: block;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background-color: #646464;
  color: #ffffff;
}

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  .camera-shoot {
    position: fixed;
    // left: 45%;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  .school-information{
    width: 100%;
    padding-top: 17%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
 .camera-stream {
    width: 50%;
    max-height: 60%;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
  }

.group-1858 {
  margin-right: 75px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  border: 4px solid #FFFFFF;
}
  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  .camera-shoot {
    position: fixed;
    // left: 170px;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  .school-information{
    width: 100%;
    padding-top: 17%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;
  }
}
</style>